@use 'styles/variables' as var;

.sticky-tabs-outer-container {
  position: relative;
  max-width: 100%;
}

.sticky-tabs-container {
  justify-content: center;
  position: sticky;
  z-index: 9;
  background-color: transparent;

  // CSS trick needed for sticky detection.
  // See article: How to Detect When a Sticky Element Gets Pinned
  // https://css-tricks.com/how-to-detect-when-a-sticky-element-gets-pinned/
  transform: translateY(-1px);

  [data-stuck='true'] & {
    background-color: var.$color-neutral-0;
  }

  // Inner container that directly wraps `Tabs` components
  > div {
    max-width: 100%;
    position: relative;
  }
}

.tabs {
  border-bottom: 1px solid var.$color-neutral-75;
}
